import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  useOutsideClick
} from '@chakra-ui/react'
import React, { FC, useState, useEffect, useContext } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { OnboardingFormContext } from '../../context/intro/OnboardingContext'
import { useField, useFormikContext } from 'formik'

export type SelectOption = {
  value: any
  label: string
}
export type SelectProps = {
  name: string
  label: string
  placeholder?: string
  offset?: string
  options: SelectOption[]
  onChange?: (value: SelectOption) => void
}

const DropDownInput: FC<SelectProps> = ({
  name,
  label = '',
  placeholder = 'select...',
  offset = '65px',
  options,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = React.useRef<HTMLInputElement>(null)
  const [field, meta, helpers] = useField({ name })
  const { submitCount, setFormikState, values } = useFormikContext()
  const { handleSetFormData, formData, isManualAddressFields } = useContext(OnboardingFormContext)

  const handleSetSelectedOption = (value: string, label: string) => {
    setIsOpen(false)
    helpers.setValue(value)
    if (onChange) {
      onChange({ value, label })
    }
  }

  useEffect(() => {
    setFormikState((prev) => ({
      ...prev,
      submitCount: 0
    }))
  }, [])

  useEffect(() => {
    if (field.name === 'dwellingType') {
      const data = { ...formData, [field.name]: field.value }
      handleSetFormData(data)
    }
  }, [field.value])

  field.onBlur = () => {}

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false)
  })

  return (
    <FormControl>
      <Flex
        position={'relative'}
        direction={'column'}
        alignItems={''}
        gap={''}
        w="full"
        pointerEvents={'all'}
      >
        {label && (
          <FormLabel
            id={`${name}-label`}
            htmlFor={`${name}-input`}
            fontSize={'small'}
            m={0}
            maxW={'85%'}
          >
            {label}
          </FormLabel>
        )}

        <Flex position={'relative'} w="full" color="black">
          <InputGroup
            display={'flex'}
            alignContent={'center'}
            position={'relative'}
            w="full"
            ref={ref}
            onClick={(e) => setIsOpen((prev) => !prev)}
          >
            <Input
              variant={'outline'}
              readOnly
              fontSize={'sm'}
              _hover={{ cursor: 'pointer' }}
              _active={{ borderColor: 'brand.primary' }}
              _focus={{ borderColor: 'brand.primary' }}
              flex={1}
              placeholder={placeholder}
              id={`${name}-input`}
              borderColor={meta?.error && submitCount > 0 ? 'red' : 'brand.input-border'}
              {...field}
            />
            <Flex
              position={'absolute'}
              right={4}
              h="100%"
              alignItems={'center'}
              justifyContent="center"
              pointerEvents={'none'}
            >
              <FiChevronDown />
            </Flex>
          </InputGroup>
        </Flex>
        {meta.error && submitCount > 0 && (
          <Text fontSize={'x-small'} color="red" m={0}>
            {meta.error}
          </Text>
        )}
        <Flex
          boxSizing="border-box"
          direction={'column'}
          bgColor={'brand.neutral'}
          border={'2px solid'}
          borderColor={'brand.input'}
          shadow="md"
          rounded="md"
          position={'absolute'}
          top={`${offset}`}
          left="0"
          w="full"
          zIndex={2}
          p={2}
          transform={isOpen ? 'scale(1)' : 'scale(0)'}
          transformOrigin={'top'}
          maxH={'300px'}
          overflowY={'auto'}
        >
          {options.map((option, index) => (
            <Flex
              key={`${option.label}-${index}} `}
              onClick={(e) => handleSetSelectedOption(option.value, option.label)}
              onTouchEnd={(e) => handleSetSelectedOption(option.value, option.label)}
              px={2}
              py={2}
              rounded={'md'}
              _hover={{ cursor: 'pointer', bgColor: 'brand.secondary', color: 'brand.text.yellow' }}
            >
              <Text fontSize={'sm'}>{option.label}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </FormControl>
  )
}

export default DropDownInput
