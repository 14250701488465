/* istanbul ignore file */
import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  SetStateAction,
  Dispatch
} from 'react'
import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser
} from 'amazon-cognito-identity-js'

import { CLIENT_HOST, COGNITO_CLIENT_ID, COGNITO_POOL_ID, USER_STORAGE_KEY } from '../../constants'
import { useBrowserStorage } from '../../hooks'
import { ErrorResponse, useGetUserQuery, User } from '../../generated/graphql'
import { UserAuth, UserStorage } from '../../types'

type AuthProviderProps = {
  isAuthenticated: boolean
  isAuthenticating: boolean
  user: UserStorage
  register: (email: string, password: string) => Promise<boolean>
  login: (email: string, password: string, rememberMe: boolean) => Promise<boolean>
  resendVerificationEmail: (email: string) => Promise<boolean>
  logout: () => void
  confirm: (
    email: string,
    code: string
  ) => Promise<{
    success: boolean
    error?: any
  }>
  resetPassword: (email: string) => Promise<void>
  setUser: Dispatch<SetStateAction<any | undefined>>
  userPool: CognitoUserPool
  cognitoUser: CognitoUser | undefined | null
  signUpError: string
  setSignUpError: Dispatch<SetStateAction<string>>
}

type AuthProviderType = {
  children?: React.ReactNode
}

const AuthContext = createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = (): Partial<AuthProviderProps> => useContext(AuthContext)

const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const { refetch: getUser } = useGetUserQuery({
    skip: true
  })

  const [rememberMe, setRememberMe] = useState(false)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined | null>()
  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_POOL_ID as string,
    ClientId: COGNITO_CLIENT_ID as string
  })

  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    USER_STORAGE_KEY,
    'local'
  )

  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    USER_STORAGE_KEY,
    'session'
  )

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(localUser || sessionUser)
  const [signUpError, setSignUpError] = useState<string>('')

  // 1. Fetch user data and update storage when the page loads or refreshes
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const email = user?.info?.email ?? undefined

        if (email) {
          try {
            const userResponse = await getUser({ email, public: true })
            const currentUser = userResponse?.data?.getUser as User

            if (currentUser) {
              // Inline persistUser logic here to avoid dependency issue
              const updatedUser: UserStorage = {
                info: {
                  ...user?.info,
                  ...currentUser
                },
                auth: {
                  ...user?.auth
                }
              }

              rememberMe ? setLocalUser(updatedUser) : setSessionUser(updatedUser)
              setUser(updatedUser)
            }
          } catch (error) {
            console.error('Failed to fetch user data', error)
          }
        }
      }
    }

    fetchUserData()
  }, [getUser, rememberMe, setLocalUser, setSessionUser])

  useEffect(() => {
    if (user) {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }
  }, [user])

  const persistUser = useCallback(
    (data: User, auth: UserAuth) => {
      const userStorage: UserStorage = {
        info: {
          ...user?.info,
          ...data
        },
        auth: {
          ...user?.auth,
          ...auth
        }
      }

      rememberMe ? setLocalUser(userStorage) : setSessionUser(userStorage)

      setUser(userStorage)
    },
    [rememberMe, user]
  )

  const logout = useCallback(async () => {
    cognitoUser && cognitoUser.signOut()
    removeLocalUser()
    removeSessionUser()
    setIsAuthenticated(false)
    setCognitoUser(null)
    setUser(undefined)
    setRememberMe(false)
  }, [cognitoUser])

  const login = useCallback(
    async (email: string, password: string, remember = false): Promise<boolean> => {
      setRememberMe(remember)

      return new Promise(async (resolve, reject) => {
        try {
          // fetch dynamo user
          const currentUserResponse = await getUser({ email, public: true })
          const currentUser = currentUserResponse?.data?.getUser as User

          if ((currentUser as ErrorResponse)?.error) {
            reject(currentUser)
          }

          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
          })

          const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
          })

          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (auth) {
              persistUser(currentUser, {
                accessToken: auth.getIdToken().getJwtToken(),
                refreshToken: auth.getRefreshToken().getToken(),
                idToken: auth.getIdToken()
              })

              setIsAuthenticated(true)
              setIsAuthenticating(false)
              resolve(true)
            },
            onFailure: function (e) {
              let error = e
              console.log(e)

              if (e.message.includes('not confirmed')) {
                error.message =
                  'Email address not verified. Please verify your email address by clicking the link in the verification email sent to you.'
              }

              reject(error)
            }
          })
        } catch (error) {
          throw error
        }
      })
    },
    [userPool]
  )

  const register = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          setSignUpError('')
          // logout
          await logout()

          // check if user exists
          const dynamoUserResponse = await getUser({ email, public: true })
          const dynamoUser = dynamoUserResponse?.data?.getUser as User

          // Check if we have returned a user
          if (!dynamoUser.email) {
            setSignUpError('Please complete your onboarding process')
            throw new Error('Please complete your onboarding process')
          } else {
            const userEmailAttribute = new CognitoUserAttribute({
              Name: 'email',
              Value: email
            })

            const originAttribute = new CognitoUserAttribute({
              Name: 'custom:origin',
              Value: CLIENT_HOST as string
            })

            userPool.signUp(
              email,
              password,
              [userEmailAttribute, originAttribute],
              [],
              async (err) => {
                if (err) {
                  console.log(err.message)
                  setSignUpError(err.message)
                  reject(err)
                }
                resolve(true)
              }
            )
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    [userPool]
  )

  const confirm = useCallback(
    async (email: string, code: string): Promise<{ success: boolean; error?: any }> => {
      return new Promise((resolve) => {
        try {
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
          })

          cognitoUser.confirmRegistration(code, true, (error) => {
            if (error) {
              resolve({
                success: false,
                error: {
                  code: error.code,
                  message: error.message,
                  name: error.name
                }
              })
            } else {
              resolve({ success: true })
            }
          })
        } catch (error) {
          if (error instanceof Error) {
            resolve({
              success: false,
              error: {
                code: (error as any).code || 'UnknownError', // In case `error` doesn't have `code`
                message: error.message || 'An unknown error occurred',
                name: error.name || 'UnknownError'
              }
            })
          } else {
            resolve({
              success: false,
              error: {
                code: 'UnknownError',
                message: 'An unknown error occurred',
                name: 'UnknownError'
              }
            })
          }
        }
      })
    },
    [userPool]
  )

  const resetPassword = useCallback(
    async (email: string): Promise<void> => {
      try {
        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: userPool
        })

        cognitoUser.forgotPassword({
          // eslint-disable-next-line
          onSuccess: function () {},
          onFailure: function (e) {
            throw e
          }
        })
      } catch (error) {
        throw error
      }
    },
    [userPool]
  )
  const resendVerificationEmail = useCallback(
    async (email: string): Promise<boolean> => {
      // check if user exists in qb and dynamo
      const currentUserResponse = await getUser({ email, public: true })
      const currentUser = currentUserResponse?.data?.getUser as User

      return new Promise((resolve, reject) => {
        try {
          if (currentUser.email) {
            // Set the current user
            const cognitoUser = new CognitoUser({
              Username: email,
              Pool: userPool
            })

            cognitoUser.resendConfirmationCode(async (e) => {
              if (e) {
                console.log('Resend verification Email error')
                reject(e)
              }
              resolve(true)
            })
            resolve(true)
          } else {
            throw new Error('Email does not exist on our system')
          }
        } catch (error) {
          throw error
        }
      })
    },
    [userPool]
  )

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        confirm,
        resetPassword,
        resendVerificationEmail,
        isAuthenticated,
        user,
        userPool,
        cognitoUser,
        isAuthenticating,
        signUpError,
        setSignUpError
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
