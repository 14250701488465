import { Button, Flex, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'

type PropertyMissingAddressFieldsModalProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
  postalCode?: string
}

function PropertyMissingAddressFieldsModal({
  isOpen,
  onClose,
  heading
}: PropertyMissingAddressFieldsModalProps) {
  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <Text textAlign={'center'} color={'brand.text.primary'}>
          It looks like Google maps could not find all the neccassary location data.
        </Text>
        <Text textAlign={'center'} color={'brand.text.primary'}>
          Please help us out by completing the address fields below.
        </Text>

        <Button alignSelf={'flex-end'} variant={'dark'} onClick={onClose}>
          Got it
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default PropertyMissingAddressFieldsModal
