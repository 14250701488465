import { Flex } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'

import MailingSubscribe from '../../../MailingSubscribe'

type HomeOwnerModalProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
}

function HomeOwnerModal({ isOpen, onClose, heading }: HomeOwnerModalProps) {
  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <MailingSubscribe
          showSubscribeElements={true}
          negativeText="Please note that we require the homeowner to sign the GoSolr subscription agreement."
          reasonForSubscribing="Not Home Owner"
          onClose={onClose}
        />
      </Flex>
    </DefaultModal>
  )
}

export default HomeOwnerModal
