/* istanbul ignore file */
import { CognitoIdToken } from 'amazon-cognito-identity-js'
import { User } from '../generated/graphql'
import { LinkProps } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'

import { DateTime } from 'luxon'

export type ObjectMap<T> = { [key: string]: T }

export type Maybe<T> = T | null | undefined

export type PropertyLocation = {
  streetAddress?: string
  fullAddress?: string
  what3Words?: string
  coordinates?: LocationCoordinates
  streetNumber?: string
  streetName?: string
  complexBuildingName?: string
  suburb?: string
  city?: string
  municipality?: string
  postalCode?: string
  province?: string
  description?: string
  country?: string
  coordinatesWithinBoundary?: Maybe<boolean>
  locationVerified?: Maybe<boolean>
}

export type PersonalDetails = {
  email: string
  firstName: string
  lastName: string
  contactNumber: string
  promoCode?: string | null
}

export type PropertyDetails = {
  dwellingType: string
  roofType: string
  meterType: string
  customerType: string
  monthlyElectricitySpend: string
  insurance: string
  description: string
}

export type MeterType = 'Pre-Paid' | 'Post-Paid'
export type CustomerType = 'Home Owner' | 'Tenant'
export type HomeOwnersInsuranceType = 'Yes' | 'No'
export type CountryType = 'South Africa'
export type DwellingType =
  | 'Free standing house'
  | 'Complex'
  | 'Townhouse'
  | 'Apartment block'
  | string
export type RoofType = 'Asbestos' | 'Corrugated' | 'Flat' | 'Other' | 'Tiles' | 'Slate' | 'Thatched'
export type ElectricitySpendType =
  | 'Below R1500'
  | 'R1500-R3000'
  | 'R3000-R5000'
  | 'R5000+'
  | "I don't know"
export type PhaseType = 'Single' | 'Three'
export type PostalCodeType = string
export type PromoCodeType = string | null

export type LocationCoordinates = {
  lat: number
  lng: number
}

export type PasswordResetInputs = {
  code: string
  code1: string
  code2: string
  code3: string
  code4: string
  code5: string
  code6: string
  password: string
  confirmPassword: string
}

export type LocationParams = {
  search: URLSearchParams | string | string[][] | Record<string, string>
  state?: {
    email: string | undefined
  }
}

export type SignupInputs = {
  email: string
  password: string
  promoCode: string
  termsAndConditions: string
}

export type LoginInputs = {
  email: string
  password: string
  rememberMe: boolean
}

export type DocumentSigningProps = {
  acceptDocumentSigning: boolean
}

export type ModalDialogProps = {
  status?: string
  title?: string
  description: string | JSX.Element
  isOpen: boolean
  onClose: () => void
  buttonTextSuccess?: string
  buttonTextError?: string
  successLink?: string
  errorLink?: string
  hasModalFooter?: boolean
  linkState?: Record<string, unknown>
  hasCloseIcon?: boolean
  showStatusIcon?: boolean
}

export type UserStorage = { auth: UserAuth; info: User }

export type UserAuth = {
  accessToken: string
  refreshToken: string
  idToken: CognitoIdToken
}

export type ExperianCreditCheckInput = {
  recordId:string
  idPassportNumber: string
  usePassportNumber: boolean
  firstName: string
  lastName: string
  streetNumber: string
  streetName: string
  suburb: string
  city: string
  postalCode: string
  dateOfBirth: string
  gender: string
  //resultType: 'PDF2' | 'JSON'
}

// Additional product addons
export type Addons = {
  two_panels: boolean
  four_panels: boolean
  battery: boolean
}

// export type UserStorage = { auth: UserAuth; info: User }

export type WalkthroughCarouselItem = {
  title: string
  description: string
  activeItem: number
  onClickNext: Dispatch<SetStateAction<number>>
}

export type SubscriptionOptionType = {
  recordId: number
  productName: string
  subscriptionOption: string
  addOns: string[]
  onceOffFee: number
  subscriptionFee: number
  flatRoofFee: number
  inverterSpec: string
  batterySpec: string
  batteryCount: string
  panelSpec: number
  panelCount: string
}

export enum CreditScoreOutcome {
  FAIL = 'FAIL',
  ON_HOLD = 'ON_HOLD',
  PASS = 'PASS'
}

export type CreditScore = {
  score?: number
  risk?: string
  outcome?: CreditScoreOutcome
}

type FooterMenuText = {
  link: string
  text: string
  openNewTab?: boolean
}

export type FooterMenuItemsProps = {
  title: string
  items: FooterMenuText[]
}

export type FooterSocialIconLink = LinkProps & {
  link: string
  icon: string
  altText: string
}

export enum TransactionTypeEnum {
  DEBIT_ORDER = 'DEBIT_ORDER',
  MAINTENANCE_FEE = 'MAINTENANCE_FEE',
  ONCE_OFF = 'ONCE_OFF'
}

export type Transaction = {
  type: TransactionTypeEnum
  description: string
  date: DateTime
  amount: number
  success: boolean
}

export enum QuickbaseOrderStatus {
  NEW_IN_REGION = 'New In region',
  NEW_OUT_OF_REGION = 'New Out of region',
  EMAIL_SENT = 'Email Sent',
  CONTACT_CALL_BACK_SET = 'Contact Call Back Set',
  CONTACT_MADE = 'Contact Made',
  AGREEMENT_SENT = 'Agreement Sent - Awaiting Signature',
  AGREEMENT_SIGNED = 'Agreement Signed',
  CREDIT_CHECK_FAILED = 'Credit Check Failed',
  CREDIT_CHECK_PASSED = 'Credit Check Passed',
  SSEG_COMPLIANCE = 'SSEG/Compliance',
  SSEG_PASSED = 'SSEG Passed',
  ON_HOLD = 'On-Hold',
  ADMIN_FEE_INVOICE_SENT = 'Once-Off Admin Fee Invoice Sent',
  AWAITING_SCHEDULING = 'Awaiting Scheduling',
  SCHEDULED_FOR_INSTALLATION = 'Scheduled for Installation',
  INSTALLATION_COMPLETE = 'Installation Complete-Customer',
  CLOSED_LOST = 'Closed Lost'
}

export type ApiAuthHeaders = {
  Authorization: string
  'X-Api-Key'?: string
}

export type SellerType = {
  name: string
  registrationNumber: string
  registeredAddress: string
  email: string
}
export type SellerBankType = {
  bank: string
  branchCode: string
  electronicAccNumber: string
}

export type AgreementCustomerType = {
  name: string
  idNumber: string
  contactPerson: string
  address: Maybe<string> | undefined
  telNo: Maybe<string> | undefined
  email: Maybe<string> | undefined
  customerCode: Maybe<string> | undefined
  ownership: Maybe<string> | undefined
  premisesOwner: string
}
export type AgreementCustomerBankType = {
  customerName: Maybe<string> | undefined
  bank: Maybe<string> | undefined
  accNumber: Maybe<string> | undefined
  accType: Maybe<string> | undefined
  branchCode: Maybe<string> | undefined
  debitOrderDate: Maybe<string> | undefined
}

export type UpgradeFeesType = {
  upgradeFrom?: string
  upgradeTo?: string
  flatRoofFee?: string
  battery?: string
  panels?: string
  upgrade?: string
  upgrade_plus_battery?: string
  upgrade_plus_panels?: string
  upgrade_plus_battery_plus_panels?: string
  upgrade_plus_battery_plus_four_panels?: string
}

export type ProductPurchaseType = {
  one?: string
  two?: string
  three?: number
  four?: number
  five?: number
  six?: number
  seven?: number
  eight?: string
}
type SolarSystemSpecType = {
  location?: string
  capacity?: Maybe<string> | undefined
  expectedStructure?: string
  additionalMaterials?: string
  de_installation_fee_first_3_Years?: string
  premisesAndLayout?: string
}
type BatterySpecType = {
  location?: string
  capacity?: Maybe<string> | undefined
  description?: Maybe<string> | undefined
  premisesAndLayout?: string
}
type InverterSpecType = {
  location?: string
  description?: Maybe<string> | undefined
  premisesAndLayout?: string
}

export type ProductFeesType = {
  product?: string
  standard_Once_Off_Installation_Fee?: string
  monthly_Fee?: string
  flat_Roof_Fee?: string
  de_installation_fee_first_3_Years?: string
  de_installation_fee_then?: string
  year_3?: number
  year_4?: number
  year_5?: number
  year_6?: number
  year_7?: number
}

export type AgreementDataType = {
  seller?: SellerType
  customer?: AgreementCustomerType
  subscriptionName?: string
  adminFee?: Maybe<number> | undefined
  additionalFlatRoofFee?: Maybe<number> | undefined
  upgradeFees?: UpgradeFeesType
  fixedMonthlyFee?: Maybe<number> | undefined
  deInstallationFee?: number
  reducedDeInstallationFee?: string
  callOutFee?: number
  annualEscalationYear?: number | string
  sellerBank?: SellerBankType
  customerBank?: AgreementCustomerBankType
  purchasePrices?: ProductPurchaseType
  solarSystemSpecs?: SolarSystemSpecType
  batterySpecs?: BatterySpecType
  inverterSpecs?: InverterSpecType
  signedAt?: string
  signedDay?: string
  signedMonthAndYear?: string
  timestamp?: string
}

export type Product = {
  addOns: string[]
  recordId: number
  productId: number
  productName: string
  onceOffFee: number
  subscriptionFee: number
  inverterSpec: string
  batterySpec: string
  batteryCount: number | null
  buyout_year_seven: number | null
  buyout_year_six: number | null
  buyout_year_five: number | null
  buyout_year_four: number | null
  buyout_year_three: number | null
  de_installation_fee_after_year_three: number | null
  de_installation_fee_year_one_to_three: number | null
  flatRoofFee: number | null
  panelCount: string
  panelSpec: string
  subscriptionOption: string
}

export type PreSignUpPropertyType = {
  propertyLat: string | number
  propertyLng: string | number
  propertyW3w: string
  fullAddress: string
  streetAddress: string
  streetNumber: string
  streetName: string
  suburb: string
  city: string
  postalCode: PostalCodeType
  province: string
  country: CountryType
  dwellingType: DwellingType | ''
  complexBuildingName: string
  roofLat: string | number
  roofLng: string | number
  roofW3w: string
  roofLocationConfirmed: boolean
  unitNumber: string
  customerType: CustomerType | ''
  monthlyElectricitySpend: ElectricitySpendType | ''
  electricityPhaseType: PhaseType | ''
  backupPowerNeeds: string | ''
  roofType: RoofType
  subscription: Product | null
  // insurance?: string
  promoCode?: string
  isRoofFlat: string
  insurance: HomeOwnersInsuranceType | ''
}

export type InitialBillingFormValueTypes = {
  debitOrderDate: string
  bankName: string
  memberName: string
  bankAccountType: string
  bankAccountNumber?: string
  branchCode: string
}

export type ConsentMessageItemType = {
  type: string
  requirments: string[]
}

export type KPIDataType = {
  dailyConsumption: string
  dailyProduction: string
  batteryCharge: string
  currentProduction: string
  activeBatterySetting: string
  gsmStatus: string
  settingsChangeStatus: SettingStatusType
}

export type SettingStatusType = 'failed' | 'completed' | 'pending'

export type PropertyInvoiceType = {
  date: string
  dueDate: string
  fullyPaidOnDate: string
  reference: string
  status: 'PAID' | 'UNPAID'
  amountDue: number
  amountPaid: number
  invoiceID: string
  invoiceNumber: string
  orderNumber: string | number
  recordId: string | number
  payments: InvoicePaymentType[]
  description: string
  type: InvoiceType
  tax?: number
  isDiscounted: boolean
  credit: number
  subTotal: number
  total: number
  discountRate: number
  discountAmount: number
}

export type InvoicePaymentType = {
  date: string
  amount: number
  id: string
}

export type InvoiceType =
  | 'admin_fee'
  | 'subscription'
  | 'de_installation'
  | 'buyout'
  | 'upgrade'
  | 'other'

export type PropertyAndTransactionsType = {
  recordId: Maybe<string> | undefined
  orderNumber: Maybe<string> | undefined
  suburb: Maybe<string> | undefined
  description: Maybe<string> | undefined
  streetAddress: Maybe<string> | undefined
  invoices: PropertyInvoiceType[]
} | null
