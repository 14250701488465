import React, { FC } from 'react'
import { Flex, StyleProps, FlexProps } from '@chakra-ui/react'

export type Props = StyleProps & FlexProps & {}

export const FlexContainer: FC<Props> = ({
  children,
  w = '50%',
  h = '300px',
  maxH = '300px',
  p = '25px',
  gap = '25px',
  rounded = '6px',
  bgColor = '#ffd635',
  shadow = 'md',
  position = 'relative',
  direction = 'column',
  overflow = 'hidden',
  ...rest
}) => {
  return (
    <Flex
      position={position}
      direction={direction}
      w={w}
      h={h}
      maxH={maxH}
      p={p}
      rounded={rounded}
      bgColor={bgColor}
      shadow={shadow}
      overflow={overflow}
    >
      {children}
    </Flex>
  )
}

export const OnboardingContainer: FC<Props> = ({
  children,
  w = '100%',
  h = 'auto',
  maxH = 'auto',
  p = '0',
  gap = '25px',
  rounded = '6px',
  bgColor = '',
  shadow = '',
  position = 'relative',
  direction = 'column',
  overflow = 'visible',
  ...rest
}) => {
  return (
    <Flex
      position={position}
      direction={direction}
      w={w}
      h={h}
      maxH={maxH}
      p={p}
      rounded={rounded}
      bgColor={bgColor}
      shadow={shadow}

      // overflow={overflow}
    >
      {children}
    </Flex>
  )
}
