import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Location } from 'react-router-dom'
import InterestedPage from '../pages/interested'
import LoginPage from '../pages/login'
import RegisterPage from '../pages/register'
import PrivateLayout from '../layout/PrivateLayout'
import NotFoundPage from '../pages/notFound'
import DashboardPage from '../pages/user/dashboard'
import AccountPage from '../pages/user/account'
import PropertiesPage from '../pages/user/properties'
import SupportRequestPage from '../pages/user/support'
import SettingsPage from '../pages/user/settings'
import PropertyPage from '../pages/user/properties/PropertyPage'
import ResetPassword from '../pages/resetPassword'
import SignUpVerificationPage from '../pages/confirmEmail'
import { useAuthContext } from '../context/AuthProvider'
import OnBoardingSubscriptions from '../pages/user/onboarding/Subscriptions'
import OnBoardingBillingPage from '../pages/user/onboarding/Billing'
import OnBoardingDocumentsPage from '../pages/user/onboarding/DocumentsPage'
import AgreementPage from '../pages/user/onboarding/Agreement'
import CreditCheckPage from '../pages/user/onboarding/CreditCheck'
import DebitOrderMandatePage from '../pages/user/agreement/DebitOrderMandate'
import SubscriptionAgreementPage from '../pages/user/agreement/SubscriptionAgreement'
import ComplianceAgreementPage from '../pages/user/agreement/ComplianceAcknowledgement'
import { useGetUserQuery, User } from '../generated/graphql'
import CreateNewPasswordPage from '../pages/resetPassword/createNewPassword'
import DashboardOnboardingCompleted from '../components/Onboarding/Dashboard/DashboardOnboardingCompleted'
import ManageSubscriptionPage from '../pages/user/manageSubscription'
import NewSubscriptionPage from '../pages/user/newSubscription'
import { assignPageTitle } from '../helpers'
import SpeakToConsultantCompletedPage from '../pages/interested/speakToConsultantCompleted'
import SubscribePage from '../pages/subscribe'
import CallMePage from '../pages/CallMe'
import BusinessCreditCheckPage from '../pages/user/onboarding/Business/BusinessCreditCheck'
import PrivateCreditCheckPage from '../pages/user/onboarding/Private/PrivateCreditCheck'
import BusinessDocumentsPage from '../pages/user/onboarding/BusinessDocumentsPage'
import CheckoutSummaryPage from '../pages/user/onboarding/CheckoutSummaryPage'
import SelectCreditType from '../pages/user/onboarding/SelectCreditType'
import OptionalUploadsPage from '../pages/user/onboarding/OptionalUploadsPage'
import FaqPage from '../pages/faq'
import OnboardingPathValidator from '../components/Onboarding/Dashboard/OnbaoringPathValidation'
import TechnicalAcknowledgementPage from '../pages/user/agreement/technicalAcknowledgement'

const Navigation = (): JSX.Element => {
  const location: Location = useLocation()
  const [pageTitle, setPageTitle] = useState<string>('Dashboard')
  const [busyOnboarding, setBusyOnboarding] = useState<boolean>(true)
  const { user, isAuthenticated, isAuthenticating } = useAuthContext()

  const { data } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = data?.getUser as User

  useEffect(() => {
    if (recentUserData && user?.info?.properties) {
      if (user?.info?.properties?.length <= 1) {
        if (user?.info?.properties[0] !== null || user?.info?.properties[0] !== undefined) {
          let shouldShowBusyOnboarding = true
          if (recentUserData.properties !== null && recentUserData.properties !== undefined) {
            const os = recentUserData.properties[0]?.orderStatus
            const installationCompleted = recentUserData?.properties[0]?.installationComplete
            if (
              os?.includes('Installation') ||
              os?.includes('Pass') ||
              os?.toLowerCase().includes('agreement signed') ||
              installationCompleted
            ) {
              shouldShowBusyOnboarding = false
            }
          }
          // console.log('Should show busy onboarding: ', shouldShowBusyOnboarding)
          setBusyOnboarding(shouldShowBusyOnboarding)
        }
      } else {
        setBusyOnboarding(false)
      }
    }
    assignPageTitle(location, setPageTitle)
  }, [location, user, recentUserData])

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/">
        <Route index element={<LoginPage />} />
        <Route path="call-me" element={<CallMePage />} />
        <Route path="speak-to-consultant-completed" element={<SpeakToConsultantCompletedPage />} />
        <Route path="onboarding/:signUpAfter" element={<InterestedPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register/">
          <Route path="confirm" element={<SignUpVerificationPage />} />
        </Route>
        <Route path="send-reset-password-link" element={<ResetPassword />} />
        <Route path="reset-password" element={<CreateNewPasswordPage />} />
        <Route path="faq" element={<FaqPage />} />
      </Route>
      {/* Private Routes */}
      <Route
        path="user/"
        element={
          <PrivateLayout
            title={pageTitle}
            isAuthenticated={isAuthenticated}
            isAuthenticating={isAuthenticating}
            busyOnboarding={busyOnboarding}
          />
        }
      >
        <Route index element={<DashboardPage />} />
        <Route path="dashboard" element={<DashboardPage key={'root-dashboard'} />} />
        {/* <Route
          path="dashboard/:newProperty"
          element={<DashboardPage key={'new-property-added-dashboard'} />}
        /> */}
        {/* <Route path="add-new" element={<AccountPage />} /> */}
        <Route path="new-subscription" element={<NewSubscriptionPage />} />
        <Route path="account" element={<AccountPage />} />
        <Route
          path="optional-uploads/:recordId"
          element={<OptionalUploadsPage setTitle={setPageTitle} />}
        />
        <Route path="properties" element={<PropertiesPage user={recentUserData} />} />
        <Route path={'property/:orderNumber'} element={<PropertyPage setTitle={setPageTitle} />} />
        <Route path="support" element={<SupportRequestPage />} />
        <Route path="manage-subscription/:orderNumber" element={<ManageSubscriptionPage />} />
        <Route path="settings" element={<SettingsPage />} />

        <Route
          path="onboarding"
          element={
            <OnboardingPathValidator
              customerNumber={user?.info.customerNumber!}
              email={user?.info?.email!}
            />
          }
        >
          <Route path="faq" element={<FaqPage />} />

        </Route>
      </Route>
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default Navigation
