import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import { Flex, StyleProps } from '@chakra-ui/react'
import { GoogleMap as ReactGoogleMap, MarkerF } from '@react-google-maps/api'

import { LocationCoordinates, Maybe } from '../../types'
import { useGoogleContext } from '../../context/GoogleProvider'

type GoogleMapProps = StyleProps & {
  onLocationChange?: (map: google.maps.Map) => Promise<void>
  coordinates: Maybe<LocationCoordinates> | undefined
  zoom?: number
  draggable?: boolean
  disableDefaultUI?: boolean
}

const GoogleMap: FC<GoogleMapProps> = ({
  onLocationChange,
  coordinates,
  zoom = 4,
  draggable = true,
  disableDefaultUI = false,
  ...rest
}) => {
  const { mapsApiIsLoaded } = useGoogleContext()
  
  const mapRef = useRef<google.maps.Map | null>(null)
  const markerRef = useRef<google.maps.Marker | null>(null)
  const defaultCenter: LocationCoordinates = {
    lat: -28.4792625,
    lng: 24.6727135
  }
  const [locationCoordinates, setLocationCoordinates] = useState<LocationCoordinates>(defaultCenter)

  useEffect(() => {
    if (coordinates) {
      setLocationCoordinates(coordinates)

      if (mapRef.current) {
        // @ts-ignore
        mapRef.current.setZoom(19)
      }
    }
  }, [coordinates, mapRef.current])

  const onLoadMap = useCallback((map: google.maps.Map) => {
    mapRef.current = map
    map.setMapTypeId(google.maps.MapTypeId.HYBRID)
  }, [])

  const onLoadMarker = useCallback((marker: google.maps.Marker) => {
    markerRef.current = marker
  }, [])

  const handleMarkerChange = useCallback(async () => {
    const map = mapRef.current

    // abort if no map ref exists
    if (!map) {
      return
    }

    const newCenter = map.getCenter()
    const newCenterCoords = newCenter ? newCenter.toJSON() : defaultCenter
    const marker = markerRef.current as google.maps.Marker
    marker.setPosition(newCenterCoords)
  }, [mapRef.current, markerRef.current])

  const handleLocationChange = useCallback(async () => {
    const map = mapRef.current

    // abort if no map ref exists
    if (!map || !onLocationChange) {
      return
    }

    await onLocationChange(map)
  }, [mapRef.current])

  return (
    <Flex direction="column" w="100%" height="300px" {...rest}>
      {mapsApiIsLoaded ? (
        <ReactGoogleMap
          id="google-map"
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            borderRadius: 10
          }}
          center={locationCoordinates}
          zoom={zoom}
          mapTypeId={google.maps.MapTypeId.HYBRID}
          tilt={0}
          onLoad={onLoadMap}
          onDragEnd={handleLocationChange}
          onDrag={handleMarkerChange}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            disableDefaultUI,
            draggable
          }}
        >
          <MarkerF position={locationCoordinates} onLoad={onLoadMarker} />
          <></>
        </ReactGoogleMap>
      ) : (
        <></>
      )}
    </Flex>
  )
}

export default GoogleMap
