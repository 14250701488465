import React, { useState } from 'react'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import axios from 'axios'

import ReCAPTCHA from 'react-google-recaptcha'

type RecaptchaProps = {
  setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void
}

function RecaptchaComponent({  setFieldValue }: RecaptchaProps) {

  const handleRecaptchaChange = async (token: string | null, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}/get-recaptcha`, { token })
    //console.log(response.data.tokenStatus)
    setFieldValue('isHuman', response.data.tokenStatus )
  }
  
  return (
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string} onChange={(token)=>handleRecaptchaChange(token, setFieldValue)} />
      </Flex>
  )
}

export default RecaptchaComponent
