import { Flex, Link, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

export const FeelFreeToContactUs = () => {
  const [cookies] = useCookies()
  const [isPartner, setIspartner] = useState<boolean>(false)

  useEffect(() => {
    if (cookies.promocode && cookies.promocode === 'hippo') {
      setIspartner(true)
    }
  }, [cookies])

  return (
    <>
      {!isPartner ? (
        <Flex w="full">
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Feel free to contact us at{' '}
            <Link href={`mailto:hello@gosolr.co.za?subject=Support%20Request`} color={'#3b82f6'}>
              hello@gosolr.co.za
            </Link>{' '}
            or give us a call at{' '}
            <Link href={`tel:0108803948`} color={'#3b82f6'}>
              010 880 3948
            </Link>{' '}
            if you require more information. Alternatively, subscribe to our mailing list and we
            will keep you posted if the situation changes.
          </Text>
        </Flex>
      ) : (
        <>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            For additional information, please reach out to our GoSolr consultants or click the
            "call me" button on the main page. Alternatively, subscribe to our mailing list and we
            will keep you posted if the situation changes.
          </Text>
        </>
      )}
    </>
  )
}
