import { FC } from 'react'
import { useFormikContext } from 'formik'
import { Button } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'

type SubmitButtonType = {
  title?: string
  isLoading?: boolean
}
const SubmitButton: FC<SubmitButtonType> = ({ title = 'Next', isLoading = false }) => {
  const { isValid, isSubmitting, touched } = useFormikContext()
  const [cookies, setCookie] = useCookies()
  return (
    <Button
      variant={cookies.promocode && cookies.promocode.toLowerCase() === 'hippo' ? 'hippo' : 'dark'}
      type={'submit'}
      isDisabled={!isValid || isSubmitting}
      isLoading={isSubmitting || isLoading}
    >
      {title}
    </Button>
  )
}

export default SubmitButton
