import { Button, Flex, Text } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import GoogleMap from '../../GoogleMap'
import { FormData, OnboardingFormContext } from '../../../context/intro/OnboardingContext'
import { useGoogleContext } from '../../../context/GoogleProvider'
import { getWhat3Words } from '../../../helpers/w3w'

type GoogleRoofMapProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  setShowRoofTypeOptions?: Dispatch<SetStateAction<boolean>>
}

function GoogleRoofMap({ setFieldValue, setShowRoofTypeOptions }: GoogleRoofMapProps) {
  const { formData, handleSetFormData } = useContext(OnboardingFormContext)
  const { googleGeocoder } = useGoogleContext()
  const [locationConfirmed, setLocationConfirmed] = useState<boolean>(false)

  const [locationCoordinates, setLocationCoordinates] = useState<{ lat: number; lng: number }>({
    lat: Number(formData.propertyLat),
    lng: Number(formData.propertyLng)
  })
  const [what3Word, setWhat3Words] = useState<string>('')

  useEffect(() => {
    const init = async () => {
      if (formData.fullAddress) {
        await getCoordinatesFromAddress(formData.fullAddress)
      }
    }

    init()
  }, [formData.fullAddress])

  // Generate Lat and long coordinates from provided address
  const getCoordinatesFromAddress = async (address: string) => {
    try {
      if (googleGeocoder) {
        try {
          await googleGeocoder.geocode(
            {
              address,
              componentRestrictions: { country: 'za' }
            },
            async (results, status) => {
              if (status === 'OK') {
                if (results && results[0]) {
                  // Get the first result in the arr
                  const result = results[0]
                  // extract lat and long values from result
                  const coords = {
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng()
                  }

                  setLocationCoordinates(coords)
                  // get What3Words
                  const w3w = await getWhat3Words(coords)
                  setWhat3Words(w3w)
                } else {
                  throw new Error('No results found')
                }
              } else {
                throw new Error('Geocoder failed due to: ' + status)
              }
            }
          )
        } catch (e) {
          console.error('FAILED TO PARSE GIVEN ADDRESS', e)
        }
      }
    } catch (e) {
      return
    }
  }

  const onLocationChange = async (map: google.maps.Map) => {
    const newCenter = map.getCenter() as google.maps.LatLng
    const newCenterCoords = newCenter.toJSON()
    setLocationCoordinates(newCenterCoords)
    setLocationConfirmed(false)
    setShowRoofTypeOptions && setShowRoofTypeOptions(false)
    setFieldValue('roofLocationConfirmed', false)
    // get What3Words
    const w3w = await getWhat3Words(newCenterCoords)
    setWhat3Words(w3w)
  }

  const handleConfirm = async () => {
    const data: FormData = {
      ...formData,
      roofW3w: what3Word,
      roofLat: locationCoordinates.lat,
      roofLng: locationCoordinates.lng,
      roofLocationConfirmed: true
    }

    if (data) {
      handleSetFormData(data)
      setFieldValue('roofLocationConfirmed', true)
      await localStorage.setItem('onboarding_property_data', JSON.stringify(data))
    }
    setLocationConfirmed(true)
    setShowRoofTypeOptions && setShowRoofTypeOptions(true)
  }
  return (
    <Flex
      id="google-map-input"
      backgroundColor="brand.neutral"
      borderRadius={'4px'}
      gap={locationConfirmed ? 0 : '25px'}
      direction="column"
      width="100%"
      height={'100%'}
      justifySelf={'start'}
      data-group
    >
      <Text mt={25} opacity={locationConfirmed ? 0 : 1} height={locationConfirmed ? '0px' : '100%'}>
        Place the pin on the roof where you prefer to install the solar panels
      </Text>
      <Flex
        overflow={'hidden'}
        style={{
          height: 'auto',
          maxHeight: locationConfirmed ? '0px' : '320px',
          opacity: locationConfirmed ? '0' : '1',
          transition: 'all 0.3s linear',
          transformOrigin: 'top',
          overflow: 'hidden'
        }}
      >
        <GoogleMap
          coordinates={locationCoordinates}
          onLocationChange={onLocationChange}
          height={{ base: '15rem', md: '20rem', lg: '20rem' }}
          overflow="hidden"
          rounded={'4px'}
        />
      </Flex>
      {!locationConfirmed ? (
        <Button variant="dark" isDisabled={locationConfirmed} mt={4} onClick={handleConfirm}>
          Confirm roof
        </Button>
      ) : (
        <Button
          variant="dark"
          mt={0}
          onClick={() => {
            setLocationConfirmed(false)
            setShowRoofTypeOptions && setShowRoofTypeOptions(false)
            setFieldValue('roofLocationConfirmed', false)
          }}
        >
          Edit Roof Location
        </Button>
      )}
    </Flex>
  )
}

export default GoogleRoofMap
