import React, { useEffect, useState } from 'react'
import { SelectedAddressProps, useGoogleContext } from '../../../context/GoogleProvider'
import { Box, Flex, Text } from '@chakra-ui/react'
import AsyncSelect from 'react-select/async'
import theme from '../../../theme'
import { Options, components, DropdownIndicatorProps } from 'react-select'
import { useDebouncedCallback } from 'use-debounce'
import { Maybe } from '../../../types'
import { BiCurrentLocation, BiInfoCircle } from 'react-icons/bi'
import { parseAddressFromGeocodeResult } from '../../../helpers'
import { getWhat3Words } from '../../../helpers/w3w'

type AsyncOption = { label: string; value: string }

type GoogleMapsSearchbarProps = {
  onAfterAddressSelected?: (selectedAddress?: SelectedAddressProps) => void
}

function GoogleMapsSearchBar({ onAfterAddressSelected }: GoogleMapsSearchbarProps) {
  const { googlePlacesService, googleGeocoder, setSelectedAddress } = useGoogleContext()
  const [currentSuggestions, setCurrentSuggestions] = useState<AsyncOption[]>([])
  const [selectedSuggestion, setSelectedSuggestion] = useState<Maybe<AsyncOption>>()

  const fetchSuggestions = useDebouncedCallback(
    (value: string, cb: (options: Options<AsyncOption>) => void): void => {
      if (!googlePlacesService || !value || value.length < 5) return cb([])
      const request: google.maps.places.AutocompletionRequest = {
        input: value,
        componentRestrictions: { country: 'za' }
      }

      googlePlacesService.getPlacePredictions(request, (predictions) => {
        const suggestions = (predictions || []).map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id
        }))

        setCurrentSuggestions(suggestions)
        localStorage.setItem('lastLocation', JSON.stringify(suggestions))
        cb(suggestions)
      })
    },
    2000
  )

  async function handleLocationSelect(option: any) {
    if (googleGeocoder) {
      try {
        const value = option.value as string
        setSelectedSuggestion(option)

        await googleGeocoder.geocode({ placeId: value }, async (results, status) => {
          if (status === 'OK') {
            if (results && results[0]) {
              const result = results[0]

              const parsedResult = parseAddressFromGeocodeResult(result)

              const coords = {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng()
              }

              if (coords) {
                const what3Words = await getWhat3Words(coords)

                const address: SelectedAddressProps = {
                  streetName: parsedResult.streetName || '',
                  streetNumber: parsedResult.streetNumber || '',
                  city: parsedResult.city || '',
                  country: parsedResult.country || '',
                  fullAddress: parsedResult.fullAddress || '',
                  suburb: parsedResult.suburb || '',
                  municipality: parsedResult.municipality || '',
                  province: parsedResult.province || '',
                  postalCode: parsedResult.postalCode || '',
                  ...coords,
                  what3Words
                }
                setSelectedAddress && setSelectedAddress(address)
                onAfterAddressSelected && onAfterAddressSelected(address)
              }
            } else {
              throw new Error('No results found')
            }
          } else {
            throw new Error('Geocoder failed due to: ' + status)
          }
        })
      } catch (e) {
        console.error('FAILED TO PARSE GIVEN ADDRESS', e)
      }
    }
  }

  useEffect(() => {
    const data = localStorage.getItem('lastLocation')
    if (data) {
      const parsedData = JSON.parse(data)
      setSelectedSuggestion(parsedData)
    }
  }, [])

  return (
    <Box id="gm-address-search" mb={4} w={'full'}>
      <Text fontSize={14} fontWeight={400} mb={1}>
        Enter your address
      </Text>
      <Flex align="center">
        <Box
          _hover={{ cursor: 'text' }}
          flex={1}
          width="100%"
          border={'1px solid'}
          borderColor="brand.input"
          bgColor={'brand.neutral'}
          rounded={'4px'}
          _groupFocusWithin={{
            borderColor: 'brand.primary'
          }}
        >
          <AsyncSelect
            autoFocus
            value={selectedSuggestion}
            styles={{
              menu: (provided) => ({
                ...provided,
                paddingTop: 5,
                paddingRight: 5,
                paddingLeft: 5
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#929292',
                fontSize: '13px',
                cursor: 'pointer'
              }),
              singleValue: (provided) => {
                const opacity = 0.8

                return { ...provided, opacity, fontSize: '13px' }
              },
              option: (provided, state) => {
                return {
                  ...provided,
                  cursor: 'pointer',
                  fontSize: '13px',
                  color: state.isSelected
                    ? theme.colors.brand.text.yellow
                    : theme.colors.brand.text.primary,
                  backgroundColor: state.isSelected
                    ? theme.colors.brand.secondary
                    : theme.colors.brand.neutral,
                  marginBottom: 5,
                  borderRadius: 5,
                  ':hover': {
                    backgroundColor: theme.colors.brand.secondary,
                    color: theme.colors.brand.text.yellow
                  }
                }
              },
              valueContainer: (provided) => {
                return {
                  ...provided,
                  padding: 5,
                  color: '#cc2020'
                }
              },
              input: (provided) => {
                return {
                  ...provided,
                  color: 'black',
                  dataGroup: true
                }
              },
              control: () => {
                const style: any = {
                  display: 'flex',
                  borderRadius: 6,
                  border: 'brand.input',
                  padding: '3px',
                  color: '#E8E9EB'
                }

                return style
              }
            }}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null
            }}
            cacheOptions
            defaultOptions={currentSuggestions}
            onChange={handleLocationSelect}
            loadOptions={fetchSuggestions}
            placeholder="Where are you located?"
            aria-label="Google Maps Seatch Field"
          />
        </Box>
      </Flex>
      <Flex align="center" height={8}>
        <Flex align="center" color="blue.500" pr={2}>
          <BiInfoCircle />
        </Flex>
        <Text fontSize={12}>Start typing to change your address</Text>
      </Flex>
    </Box>
  )
}

export default GoogleMapsSearchBar

const DropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props} isFocused={true}>
      <BiCurrentLocation />
    </components.DropdownIndicator>
  )
}
