/* istanbul ignore file */
import axios from 'axios'
import { LocationCoordinates } from '../../types'
import { W3W_KEY } from '../../constants'

export const getWhat3Words = async (coords: LocationCoordinates): Promise<string> => {
  try {
    const response = await axios.get(
      `https://api.what3words.com/v3/convert-to-3wa?coordinates=${coords.lat}%2C${coords.lng}&key=${W3W_KEY}`
    )

    return response?.data?.words
  } catch (e) {
    console.log({ e })
    throw e
  }
}
