import { Button, Flex, Img, Link, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { User, useUpdateUserMutation } from '../../generated/graphql'

type TermsAndConditionsPropsType = {
  user: User
}

const TermsAndConditionsModal = ({ user }: TermsAndConditionsPropsType) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateUser] = useUpdateUserMutation()
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSaveTermsAccepted = async () => {
    setLoading(true)
    // Store the terms and conditions accepted in local storage
    await localStorage.setItem(
      'termsAccepted',
      JSON.stringify({ termsAccepted: true, email: user.email! })
    )

    // Update the user DynamoDB record ** Waiting for James to finalize backend for this
    // const updatedUser = await updateUser(user.email!, { acceptedTermsAndConditions: 'true' })
    const updatedUser = await updateUser({
      variables: {
        email: user.email!,
        input: {
          recordId: user.recordId!, // this must be the record id of the user property
          acceptedTermsAndConditions: 'true'
        }
      }
    })
    setLoading(false)
    setIsOpen(false)
  }

  useEffect(() => {
    const CheckTermsAccepted = async () => {
      // Check if the terms and conditions have been accepted on local storage
      const localStorageTermsAccepted = (await localStorage.getItem('termsAccepted')) || ''

      // If  local storage item is found, check the user and compare the email
      if (localStorageTermsAccepted) {
        const { termsAccepted: termsAcceptedValue, email } = JSON.parse(localStorageTermsAccepted)
        let isAccepted = termsAcceptedValue && email === user.email
        console.log('Terms accepted: ', termsAcceptedValue && email === user.email)
        setIsOpen(!isAccepted)
        return
      }
      console.log('could not find local storage item')

      // if no local storage item is found, check the user object from dynamoDB
      const isUserTermsAccepted = user.acceptedTermsAndConditions

      if (isUserTermsAccepted) {
        // If user has accepted terms and conditions,
        await localStorage.setItem(
          'termsAccepted',
          JSON.stringify({ termsAccepted: true, email: user.email! })
        )
        // close the modal
        setIsOpen(false)
      }

      //If no Terms and conditions accepted, open the modal
      setIsOpen(true)
    }
    CheckTermsAccepted()
  }, [])

  return (
    <Modal
      isCentered
      blockScrollOnMount={true}
      isOpen={isOpen}
      size={'2xl'}
      onClose={handleClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />

      <ModalContent
        bgColor={'#ffc801'}
        alignItems={'center'}
        justifyContent={'center'}
        rounded={'full'}
        padding={25}
        shadow={'none'}
        width={'100%'}
        height={'100%'}
        maxH={['300px', '500px', '500px', '500px', '500px']}
        maxW={['300px', '500px', '500px', '500px', '500px']}
      >
        <Img
          src={'/images/GoSolr_WebAppDesign_TsCs.png'}
          objectFit="cover"
          w={'full'}
          boxSize={['85%', '95%', '95%', '95%', '95%']}
          // shadow={'lg'}
          rounded={'full'}
          top={0}
          pos={'absolute'}
        />

        <Flex
          pos={'absolute'}
          direction={'column'}
          alignItems={'center'}
          gap={['10px', '15px', '15px', '15px', '15px']}
          bottom={['10%', '10%', '10%', '10%', '10%']}
        >
          <Link
            fontWeight={400}
            fontSize={['smaller', 'md', 'lg', 'xl', 'xl']}
            href="https://gosolr-static-files.s3.eu-west-1.amazonaws.com/gosolr_website_terms.pdf"
            color="black"
            target={'_blank'}
            textDecor={'underline'}
          >
            Terms & conditions
          </Link>

          <Button
            color={'#ffbe00'}
            bgColor={'#000000'}
            rounded={'full'}
            onClick={(e) => handleSaveTermsAccepted()}
            fontSize={['smaller', 'md', 'lg', 'xl', 'xl']}
            isLoading={loading}
            maxW={'min-content'}
            // padding={['10px', '15px', '15px', '', '']}
            variant={'dark'}
          >
            Accept
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default TermsAndConditionsModal
