import { FC } from 'react'
import { Flex, Text, Button, ButtonProps } from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import { useFormikContext } from 'formik'

export type BackButton = {
  callback: () => void
} & ButtonProps

const BackButton: FC<BackButton> = ({ callback = () => {} }) => {
  const { setSubmitting } = useFormikContext()
  const handleBackButton = () => {
    setSubmitting(false)
    callback()
  }
  return (
    <Button
      onClick={handleBackButton}
      variant={'none'}
      bg="brand.neutral"
      color="brand.text.primary"
      _active={{ transform: 'scale(0.95)' }}
      type={'button'}
    >
      <Flex alignItems={'center'}>
        <FiArrowLeft />
        <Text pl={'2px'} _groupHover={{ pl: '5px' }} transition={'padding 0.2s ease-in-out'}>
          Back
        </Text>
      </Flex>
    </Button>
  )
}

export default BackButton
