import React, { useEffect, useState } from 'react'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'
import TextInput from '../../../FormElements/TextInput'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import SubmitButton from '../../../FormElements/SubmitButton'
import { CallMeValidation } from '../../../../formValidation/CallMe'
import PhoneInput from '../../../FormElements/PhoneInput'
import { SA_PROVINCE_OPTIONS } from '../../../../constants'
import BackButton from '../../../FormElements/BackButton'
import DropDownInput from '../../../FormElements/DropDownInput'
import RecaptchaModal from './RecaptchaModal'
import RecaptchaComponent from '../../../FormElements/RecaptchaComponent'
import { fontSize } from 'styled-system'

type CallMeModalProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
  isUnsupportedLocation: boolean
}
type InitialValuesType = {
  firstName: string
  lastName: string
  email: string
  contactNumber: string
  provinces: string
  streetName: string
  streetNumber: string
  city: string
  suburb: string
  postalCode: string
  productOfInterest: string
  isHuman: boolean
  origin: string
}

function CallMeModal({ isOpen, onClose, heading, isUnsupportedLocation }: CallMeModalProps) {
  const initValues: InitialValuesType = {
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    provinces: '',
    streetName: '',
    streetNumber: '',
    city: '',
    suburb: '',
    postalCode: '',
    productOfInterest: '',
    isHuman: false,
    origin: "Webapp (call me)"
  }
  const [recaptchaCompleted, setRecaptchaCompleted] = useState<boolean>(false)

  const handleSubmit = async (values: InitialValuesType) => {
    const data = values
    if (data) {
      //axios post request to backend
      try {
        await axios
          .post(process.env.REACT_APP_API_HOST + '/post-call-me-input', data)
          .then(function (response) {
            //onSubmit async function. so we wait till axios request is finished
            onClose()
          })
          .catch(function (error) {}) //then function returns endpoints response
      } catch (e) {
        console.log('error occurred: ', e)
      }
    }
  }

  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex>
        <Formik
          initialValues={initValues}
          validateOnMount
          validateOnBlur={false}
          enableReinitialize
          validationSchema={CallMeValidation}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <br></br>
              <Flex direction={'column'} w="full" gap={'10px'} mb={25}>
                <div>Personal Information</div>
              </Flex>
              <Flex direction={'column'} w="full" gap={'25px'} fontSize={12} mb={25}>
                <div>Please provide us with your information and we will get back to you. </div>
              </Flex>
              <Flex gap={'25px'}></Flex>
              <Flex direction={'column'} w="full" gap={'15px'}>
                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput name="firstName" label="First name" placeholder="First name" />
                  <TextInput name="lastName" label="Last name" placeholder="Last name" />
                </Flex>
                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <PhoneInput
                    name="contactNumber"
                    label="Phone number"
                    placeholder="Phone number"
                  />
                  <TextInput name="email" label="Email" placeholder="Email address" />
                </Flex>
                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput name="streetName" label="Street name" placeholder="Street name" />
                  <TextInput
                    name="streetNumber"
                    label="Street number"
                    placeholder="Street number"
                  />
                </Flex>

                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput name="city" label="City" placeholder="City" />

                  <DropDownInput
                    name="provinces"
                    label="Province"
                    options={SA_PROVINCE_OPTIONS}
                    placeholder={'Select a province'}
                  />
                </Flex>
                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput name="suburb" label="Suburb" placeholder="Suburb" />
                  <TextInput name="postalCode" label="Postal code" placeholder="Postal code" />
                </Flex>
                <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput
                    name="productOfInterest"
                    label="Additional informaton"
                    placeholder="Product of interest/Any comments"
                  />
                </Flex>
                <IsHumanObserver values = {values} setFieldValue={setFieldValue}/>
                <Flex justifyContent={'space-between'}>
                  {/* <Link to="/login"> */}
                  <BackButton
                    callback={() => {
                      onClose() // handlePrevStepIndex()
                    }}
                  />
                  {/* </Link> */}
                  <SubmitButton />
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </DefaultModal>
  )
}
export default CallMeModal

const IsHumanObserver = ({
  values,
  setFieldValue
}: {
  values: InitialValuesType
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false)
  useEffect(() => {
    setShouldShow(values.isHuman)
  }, [values.isHuman])
  return <>{!shouldShow && <RecaptchaComponent setFieldValue={setFieldValue} />}</>
}
