import * as yup from 'yup'
import {
  DWELLING_TYPE_REGEX,
  ELECTRICITY_PHASE_OPTIONS,
  ELECTRICITY_SPEND_OPTIONS,
  HOMEOWNERS_INSURANCE_OPTIONS,
  ROOF_ANGLE_OPTIONS,
  ROOF_TYPE_VALIDATION_OPTIONS,
  SA_PROVINCE_ARR
} from '../../constants'

type objArr = {
  value: string
  label: string
}

const objArrToIndexArr = (objArr: objArr[]) => {
  let newArr = objArr.map((obj) => obj.value)
  return newArr
}

// New Address Validation Validation
export const PropertyAddressValidation = yup.object().shape({
  streetName: yup.string().required('Street address is required.'),
  streetNumber: yup.string().required('Street address is required.'),
  suburb: yup.string().required('Suburb is required.'),
  city: yup.string().required('City is required.'),
  province: yup.string().oneOf(SA_PROVINCE_ARR).required('Province is required.'),
  postalCode: yup
    .string()
    .min(4, 'Postal codes must be 4 digits')
    .max(4, 'Postal codes must be 4 digits')
    .required('Postal code is required.'),
  customerType: yup.mixed().oneOf(['Home owner']).required('Select if you own or rent'),
  isInsured: yup
    .string()
    .oneOf(objArrToIndexArr(HOMEOWNERS_INSURANCE_OPTIONS))
    .required('Indicate whether insured')
})
export const PropertyAddressValidationHippo = yup.object().shape({
  streetName: yup.string().required('Street address is required.'),
  streetNumber: yup.string().required('Street address is required.'),
  suburb: yup.string().required('Suburb is required.'),
  city: yup.string().required('City is required.'),
  province: yup.string().oneOf(SA_PROVINCE_ARR).required('Province is required.'),
  postalCode: yup
    .string()
    .min(4, 'Postal codes must be 4 digits')
    .max(4, 'Postal codes must be 4 digits')
    .required('Postal code is required.'),
  customerType: yup.string().required('Select if you own or rent'),
  isInsured: yup.string().required('Indicate whether insured')
})

export const CheckoutSummaryPropertyAddressValidation = yup.object().shape({
  streetName: yup.string().required('Street address is required.'),
  streetNumber: yup.string().required('Street address is required.'),
  suburb: yup.string().required('Suburb is required.'),
  city: yup.string().required('City is required.'),
  province: yup.string().oneOf(SA_PROVINCE_ARR).required('Province is required.'),
  postalCode: yup
    .string()
    .min(4, 'Postal codes must be 4 digits')
    .max(4, 'Postal codes must be 4 digits')
    .required('Postal code is required.')
})

// New Rooftype validation
export const RoofTypeValidation = yup.object().shape({
  roofType: yup.mixed().oneOf(ROOF_TYPE_VALIDATION_OPTIONS).required('Select a roof type'),
  dwellingType: yup
    .string()
    .required('Dwelling type required.')
    .matches(DWELLING_TYPE_REGEX, 'Valid dwelling type required'),
  isRoofFlat: yup
    .string()
    .oneOf(objArrToIndexArr(ROOF_ANGLE_OPTIONS))
    .required('Please specify if roof is flat.'),
  complexBuildingName: yup.string().when('dwellingType', {
    is: 'Complex' || 'Townhouse' || 'Apartment block',
    then: () => yup.string().required('Complex/Estate/Building name is required.')
  }),
  unitNumber: yup.string().when('dwellingType', {
    is: 'Complex' || 'Townhouse' || 'Apartment block',
    then: () => yup.string().required('Unit number name is required.')
  }),
  roofLocationConfirmed: yup.boolean().oneOf([true])
})

// Electricity Needs
export const ElectricityNeedsValidation = yup.object().shape({
  electricityPhaseType: yup
    .string()
    .oneOf(objArrToIndexArr(ELECTRICITY_PHASE_OPTIONS))
    .required('Select your meter type'),
  monthlyElectricitySpend: yup
    .string()
    .oneOf(objArrToIndexArr(ELECTRICITY_SPEND_OPTIONS))
    .required('Select an estimated monthly electricity usage.'),
  backupPowerNeeds: yup
    .string()
    .oneOf(['Below stage 4', 'Above stage 4'])
    .required('Select a backup power option.')
})
