import { Box, Flex, Image } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { OnboardingContainer } from '../../../containers/Global'
import { ElectricityNeedsValidation } from '../../../../formValidation/preSignUpFlow'
import { OnboardingFormContext, FormData } from '../../../../context/intro/OnboardingContext'
import { ElectricitySpendType, PhaseType } from '../../../../types'
import SubmitButton from '../../../FormElements/SubmitButton'
import BackButton from '../../../FormElements/BackButton'
import DropDownInput from '../../../FormElements/DropDownInput'
import {
  BACKUP_POWER_OPTIONS,
  ELECTRICITY_PHASE_OPTIONS,
  ELECTRICITY_SPEND_OPTIONS
} from '../../../../constants'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import Tooltip from '../../../Tooltip'
import CallMeModal from '../CallMe/CallMeModal'
import { useLocation } from 'react-router-dom'

type ElectricityNeedsInitailValuesType = {
  electricityPhaseType: PhaseType | ''
  monthlyElectricitySpend: ElectricitySpendType | ''
  backupPowerNeeds: string
}

function ElectricityNeeds() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [modalHeading] = useState<string>('Please note!')
  const [locationUnsupported] = useState<boolean>(false)
  const { handleNextStepIndex, handleSetFormData, handlePrevStepIndex, formData } =
    useContext(OnboardingFormContext)
  const [switchImage, setSwitchImage] = useState<string>('./images/phases.png')
  const location = useLocation()

  //   The initial values our form gets loaded with
  const initialValues: ElectricityNeedsInitailValuesType = {
    electricityPhaseType: formData?.electricityPhaseType || '',
    monthlyElectricitySpend: formData?.monthlyElectricitySpend || '',
    backupPowerNeeds: formData?.backupPowerNeeds || ''
  }

  useEffect(() => {
    if (location.pathname.includes('user/new-subscription')) {
      setSwitchImage('/images/phases.png')
    } else {
      setSwitchImage('./images/phases.png')
    }
  }, [location.pathname])

  const handleSubmit = async (values: ElectricityNeedsInitailValuesType) => {
    const { electricityPhaseType, monthlyElectricitySpend, backupPowerNeeds } = values

    try {
      if (electricityPhaseType && monthlyElectricitySpend && backupPowerNeeds) {
        const data: FormData = { ...formData, ...values }
        if (data) {
          await localStorage.setItem('onboarding_property_data', JSON.stringify(data))
          handleSetFormData(data)

          // Save location details to local storage
          handleNextStepIndex()
          return
        }

        throw new Error(
          'MeterType,monthlyElectricitySpend or backupPowerNeeds can not be undefined and can not be an empty string'
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <OnboardingContainer maxH={'100vh'} overflow="visible" gap="50px" pb="100px">
      <CallMeModal
        isOpen={modalIsOpen}
        isUnsupportedLocation={locationUnsupported}
        onClose={() => setModalIsOpen(false)}
        heading={modalHeading}
      />
      <Flex w={'full'} mb={'25px'} />
      <Formik
        initialValues={initialValues}
        validateOnMount
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={ElectricityNeedsValidation}
        enableReinitialize
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form style={{ width: '100%' }}>
          <Flex width={'full'} gap={'25px'} direction={'column'}>
            <Flex position={'relative'} gap={'25px'} alignItems={'center'} direction={['row']}>
              <DropDownInput
                name="electricityPhaseType"
                label="Does the property have single or three-phase electricity?"
                placeholder="Select phase type"
                options={ELECTRICITY_PHASE_OPTIONS}
              />
              <Tooltip
                label="You can check your electricity phase type in your main DB-board. Look at the main switch. A single switch (left) means you're on a single-phase, whereas three switches combined in one means you're on three-phase electricity."
                ariaLabel="Electricity phase tooltip"
              >
                <Box
                  position={'absolute'}
                  right={[2, 2, -20, -20]}
                  top={[-2, -2, 6, 6]}
                  cursor={'pointer'}
                  p={1}
                >
                  <IoMdInformationCircleOutline size={25} />
                </Box>
              </Tooltip>
            </Flex>
            <Flex width={'full'} justifyContent={'center'} alignItems={'center'}>
              <Image src={switchImage} alt={'Electricity phase switches'} />
            </Flex>
            <Flex
              width={'full'}
              gap={'25px'}
              position={'relative'}
              alignItems={'center'}
              direction={['row']}
            >
              <DropDownInput
                name="monthlyElectricitySpend"
                label="What is your normal electricity bill per month?"
                placeholder="Select amount"
                options={ELECTRICITY_SPEND_OPTIONS}
              />
              <Tooltip
                label="We will use this to recommend the right system size for your home."
                ariaLabel="Electricity spend tooltip"
              >
                <Box
                  position={'absolute'}
                  right={[2, 2, -20, -20]}
                  top={[-2, -2, 6, 6]}
                  cursor={'pointer'}
                  p={1}
                >
                  <IoMdInformationCircleOutline size={25} />
                </Box>
              </Tooltip>
            </Flex>
            <Flex
              position={'relative'}
              width={'full'}
              gap={'25px'}
              direction={['row']}
              alignItems={'center'}
            >
              <DropDownInput
                name="backupPowerNeeds"
                label="What are your needs for backup power during load-shedding?"
                placeholder="Select loadshedding protection"
                options={BACKUP_POWER_OPTIONS}
              />
              <Tooltip
                label="Our solutions are designed to give you enough power up to stage 4 load-shedding. If you want to stay powered above stage 4 you might need additional batteries. 

                This can be selected in the next step."
                ariaLabel="Backup power needs during load-shedding tooltip"
              >
                <Box
                  position={'absolute'}
                  right={[2, 2, -20, -20]}
                  top={[-2, -2, 6, 6]}
                  cursor={'pointer'}
                  p={1}
                >
                  <IoMdInformationCircleOutline size={25} />
                </Box>
              </Tooltip>
            </Flex>
            <Flex justifyContent={'space-between'} mt={'25px'}>
              <BackButton
                callback={() => {
                  handlePrevStepIndex()
                }}
              />
              <SubmitButton />
            </Flex>
          </Flex>
        </Form>
      </Formik>
    </OnboardingContainer>
  )
}

export default ElectricityNeeds
