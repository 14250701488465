import { Flex, useRadio, RadioProps, Box, chakra, Text, Center } from '@chakra-ui/react'
import React, { FC, useState, useEffect } from 'react'
import { FiCheck } from 'react-icons/fi'
import {
  RoofTiles,
  RoofSlate,
  RoofCorrugated,
  RoofCement,
  RoofAsbestos,
  RoofThatched,
  RoofOther
} from '../Icons/Rooftypes'
export type customRadioType = {
  label: string
} & RadioProps

const RoofTypeRadio: FC<customRadioType> = (props) => {
  const { ...radioProps } = props
  const { state, getInputProps, htmlProps } = useRadio(radioProps)
  const [roofIcon, setRoofIcon] = useState<React.ReactNode>(<RoofTiles />)

  const roofStyles = { fill: 'black', strokeWidth: '2px' }

  useEffect(() => {
    let roofTypeIcon
    switch (props.label.toLowerCase()) {
      case 'tiles':
        roofTypeIcon = <RoofTiles style={roofStyles} />
        break
      case 'slate':
        roofTypeIcon = <RoofSlate style={roofStyles} />
        break
      case 'corrugated':
        roofTypeIcon = <RoofCorrugated style={roofStyles} />
        break
      case 'cement':
        roofTypeIcon = <RoofCement style={roofStyles} />
        break
      case 'asbestos':
        roofTypeIcon = <RoofAsbestos style={roofStyles} />
        break
      case 'thatched':
        roofTypeIcon = <RoofThatched style={roofStyles} />
        break
      case 'other':
        roofTypeIcon = <RoofOther style={roofStyles} />
        break

      default:
        roofTypeIcon = <RoofTiles style={roofStyles} />
        break
    }

    setRoofIcon(roofTypeIcon)
  }, [])

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Flex
        direction={'column'}
        gap="10px"
        alignItems={'center'}
        justifyContent="space-between"
        height={'full'}
        data-group
      >
        <Text color={'brand.text.primary'} fontSize={['xs', 'xs', 'sm', 'sm']}>
          {props.label}
        </Text>
        <Center
          flex={1}
          h="full"
          minH={'30px'}
          maxH={'20px'}
          color={state.isChecked ? 'brand.text.yellow' : 'brand.text.primary'}
          _groupHover={{ transform: 'scale(1.1)' }}
          _groupActive={{ transform: 'scale(0.95)' }}
          transition={'transform 0.2s ease-in-out'}
        >
          {roofIcon}
        </Center>
        <Flex
          w={'20px'}
          h={'20px'}
          bg={state.isChecked ? 'brand.primary' : 'brand.input'}
          border={'2px solid'}
          borderColor={state.isChecked ? 'transparent' : 'brand.text.primary'}
          transition={'all 0.2s ease-in-out'}
          rounded={'full'}
          _groupActive={{ transform: 'scale(0.25)' }}
        >
          {state.isChecked && <FiCheck />}
        </Flex>
      </Flex>
    </chakra.label>
  )
}

export default RoofTypeRadio
