import { useContext, useEffect, useRef, useState } from 'react'
import { OnboardingContainer } from '../../../containers/Global'
import { Form, Formik, FormikState, FormikValues, useFormikContext, FormikProps } from 'formik'
import GoogleMapsSearchBar from '../../../FormElements/GoogleMapInput/GoogleMapsSearchBar'
import { Box, Button, Flex } from '@chakra-ui/react'
import TextInput from '../../../FormElements/TextInput'
import SubmitButton from '../../../FormElements/SubmitButton'
import { useGoogleContext } from '../../../../context/GoogleProvider'
import { FormData, OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import PropertyLocationModal from './PropertyLocationModal'
import {
  PropertyAddressValidation,
  PropertyAddressValidationHippo
} from '../../../../formValidation/preSignUpFlow'
import DropDownInput from '../../../FormElements/DropDownInput'
import {
  CUSTOMER_TYPE_OPTIONS,
  HOMEOWNERS_INSURANCE_OPTIONS,
  SA_PROVINCE_OPTIONS
} from '../../../../constants'
import Tooltip from '../../../Tooltip'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { CustomerType, HomeOwnersInsuranceType } from '../../../../types'

import HomeOwnerModal from './HomeOwnerModal'
import InsuranceModal from './InsuranceModal'
import PropertyMissingAddressFieldsModal from './PropertyMissigDetailsModal'

import { CookiesModal } from '../../../Modals'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import * as yup from 'yup'
export type PropertyLocationInitialValuesType = {
  streetName: string
  streetNumber: string
  city: string
  suburb: string
  province: string
  postalCode: string
  customerType: CustomerType | string
  isInsured: HomeOwnersInsuranceType | string
}

//json object for postal codes retrieved
interface PostalCodeData {
  Open: string
  strCode: string
  area: string
}

function PropertyLocation() {
  const { selectedAddress } = useGoogleContext()
  const { handleNextStepIndex, handleSetFormData, formData } = useContext(OnboardingFormContext)
  const [propertyModalIsOpen, setPropertyModalIsOpen] = useState<boolean>(false)
  const [missingAddressFieldsModalIsOpen, setMissingAddressFieldsModalIsOpen] =
    useState<boolean>(false)
  const [insuranceModalIsOpen, setInsuranceModalIsOpen] = useState<boolean>(false)
  const [callModalIsOpen, setCallModalIsOpen] = useState<boolean>(false)
  const [modalHeading, setModalHeading] = useState<string>('Please note!')
  const [locationUnsupported, setLocationUnsupported] = useState<boolean>(false)
  const [vewManualAddressInputs, setViewManualAddressInputs] = useState<boolean>(false)
  const [showCookiesModal, setShowCookiesModal] = useState<boolean>(true)
  const [isHippo, setIsHippo] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<PropertyLocationInitialValuesType>(() => ({
    streetName: selectedAddress?.streetName || '',
    streetNumber: selectedAddress?.streetNumber || '',
    city: selectedAddress?.city || '',
    suburb: selectedAddress?.suburb || '',
    province: selectedAddress?.province || '',
    postalCode: selectedAddress?.postalCode || '',
    customerType: formData?.customerType || '',
    isInsured: formData?.insurance || ''
  }))

  const isPageFirstLoad = useRef<boolean>(true)
  const [cookies, setCookie] = useCookies()
  // When the google location changes update the form initial values
  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      ...selectedAddress
    }))
  }, [selectedAddress])

  useEffect(() => {
    setIsHippo(cookies.promocode && cookies.promocode.toLowerCase() === 'hippo')
  }, [cookies])

  useEffect(() => {
    // Check if any of the google maps values are empty
    if (selectedAddress && isPageFirstLoad.current === false) {
      const { streetName, streetNumber, city, suburb, province, postalCode } = selectedAddress
      if (!streetName || !streetNumber || !city || !suburb || !province || !postalCode) {
        setMissingAddressFieldsModalIsOpen(true)
        setViewManualAddressInputs(true)
      }
    }
  }, [selectedAddress])

  const validatePostalCode = async (postalCode: string) => {
    console.log('Validating postal code:', postalCode)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/check-postal-code`,
        {
          postalCode: postalCode
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      console.log('Results = ', response.data)
      const postalValid = response.data.postalCodeFound
      return postalValid
    } catch (e) {
      console.error('Failed to validate postal code', e)
      return false
    }
  }

  async function handelSubmit(values: PropertyLocationInitialValuesType) {
    const {
      streetName,
      streetNumber,
      postalCode,
      city,
      province,
      suburb,
      customerType,
      isInsured
    } = values

    // Check if we support the provided postal code
    if (postalCode) {
      // If invalid postal code
      let validPostalCode = await validatePostalCode(postalCode)
      console.log('Valid postal code ?', validPostalCode)
      if (!validPostalCode) {
        setPropertyModalIsOpen(true)
        setLocationUnsupported(true)
        setModalHeading('Location not supported.')
        return
      }
    }

    // retrieve our property latLong W3w
    // Update our form State
    if (selectedAddress) {
      const propertyLat = selectedAddress?.lat
      const propertyLng = selectedAddress?.lng
      const propertyW3w = selectedAddress?.what3Words as string
      const streetAddress = streetNumber && streetName ? `${streetNumber} ${streetName}` : ''

      // Build full address
      const fullAddress = `${streetAddress}, ${suburb}, ${city}, ${postalCode}, South Africa`

      if (propertyLat !== undefined || propertyLng !== undefined || propertyW3w !== undefined) {
        const data: FormData = {
          ...formData,
          streetNumber,
          streetName,
          fullAddress,
          postalCode,
          city,
          province,
          suburb,
          streetAddress,
          propertyLat,
          propertyLng,
          propertyW3w,
          customerType: customerType && (customerType as CustomerType),
          insurance: isInsured && (isInsured as HomeOwnersInsuranceType)
        }

        if (data) {
          handleSetFormData(data)
          // Save location details to local storage
          await localStorage.setItem('onboarding_property_data', JSON.stringify(data))
          handleNextStepIndex()
        }
      }
    }
  }

  return (
    <OnboardingContainer maxH={'100vh'} overflow="visible" gap="50px" pb="100px">
      <CookiesModal
        cookiesTitle={'Disclaimer'}
        cookiesMsg={
          'Our website uses cookies to enhance your browsing experience; by continuing to use our site, you consent to our use of cookies.'
        }
        isOpen={showCookiesModal}
        onClose={function (): void {
          setShowCookiesModal(false)
        }}
      ></CookiesModal>

      <PropertyLocationModal
        isOpen={propertyModalIsOpen}
        onClose={() => setPropertyModalIsOpen(false)}
        heading={modalHeading}
        postalCode={selectedAddress?.postalCode}
      />
      <PropertyMissingAddressFieldsModal
        isOpen={missingAddressFieldsModalIsOpen}
        onClose={() => setMissingAddressFieldsModalIsOpen(false)}
        heading={'Missing address values'}
      />
      <InsuranceModal
        isOpen={insuranceModalIsOpen}
        onClose={() => setInsuranceModalIsOpen(false)}
        heading={modalHeading}
      />

      <Flex w="full" justify={'end'} mb="25">
        <Button
          variant={isHippo ? 'hippo' : 'dark'}
          onClick={() => setViewManualAddressInputs((prev) => !prev)}
        >
          {vewManualAddressInputs ? 'Use google maps' : 'Manual address input'}
        </Button>
      </Flex>
      {!vewManualAddressInputs && (
        <Flex pos={'relative'} direction={'column'} w={'full'} mb={'25px'} alignItems={'center'}>
          <GoogleMapsSearchBar onAfterAddressSelected={() => (isPageFirstLoad.current = false)} />
          <Tooltip
            label="Can't find your address? Add it manually and click “Next”"
            ariaLabel="Google maps input"
          >
            <Box
              position={'absolute'}
              right={[2, 2, -20, -20]}
              top={[-1, -1, 10, 10]}
              cursor={'pointer'}
            >
              <IoMdInformationCircleOutline size={25} />
            </Box>
          </Tooltip>
        </Flex>
      )}
      <Formik
        initialValues={initialValues}
        validateOnMount
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={!isHippo ? PropertyAddressValidation : PropertyAddressValidationHippo}
        enableReinitialize
        onSubmit={(values) => handelSubmit(values)}
      >
        {(formikProps: FormikProps<PropertyLocationInitialValuesType>) => (
          <Form>
            <HomeOwnerObserver isHippo={isHippo} />
            <Flex gap={'25px'} direction={'column'}>
              {vewManualAddressInputs && (
                <>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <TextInput name="streetName" label="Street name" placeholder="Street name" />
                    <TextInput
                      name="streetNumber"
                      label="Street number"
                      placeholder="Street number"
                    />
                  </Flex>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <TextInput name="city" label="City" placeholder="City" />
                    <TextInput name="suburb" label="Suburb" placeholder="Suburb" />
                  </Flex>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <DropDownInput
                      name="province"
                      label="Province"
                      options={SA_PROVINCE_OPTIONS}
                      placeholder={'Province'}
                    />
                    <TextInput name="postalCode" label="Postal code" placeholder="Postal code" />
                  </Flex>
                </>
              )}
              <Flex
                position={'relative'}
                width={'100%'}
                direction={['column', 'column', 'row', 'row']}
                gap={25}
              >
                <Flex position={'relative'} width={'100%'}>
                  <DropDownInput
                    name="customerType"
                    label="Do you own or rent?"
                    options={CUSTOMER_TYPE_OPTIONS}
                    placeholder={'Do you own or rent?'}
                    onChange={(option) => {
                      formikProps.setFieldValue('customerType', option.value)
                      formikProps.initialValues.customerType = option.value
                      setInitialValues({ ...formikProps.initialValues })
                    }}
                  />
                  <Tooltip
                    label="Note that only property owners can sign the agreement"
                    ariaLabel="Property Ownership required tooltip"
                    placement="top-end"
                  >
                    <Box
                      position={'absolute'}
                      left={['auto', 'auto', -20, -20]}
                      right={[2, 2, 'auto', 'auto']}
                      top={[-2, -2, 7, 7]}
                      cursor={'pointer'}
                    >
                      <IoMdInformationCircleOutline size={25} />
                    </Box>
                  </Tooltip>
                </Flex>

                <Flex position={'relative'} width={'100%'} direction={['row']}>
                  <DropDownInput
                    name="isInsured"
                    label="Is your property insured?"
                    options={HOMEOWNERS_INSURANCE_OPTIONS}
                    placeholder={'Insured - Yes / No ?'}
                    onChange={(option) => {
                      formikProps.setFieldValue('isInsured', option.value)
                      formikProps.initialValues.isInsured = option.value
                      if (option.value === 'No' && !isHippo) {
                        setInsuranceModalIsOpen(true)
                      }
                      setInitialValues({ ...formikProps.initialValues })
                    }}
                  />

                  <Tooltip
                    label="We require the property to be covered by a valid homeowners insurance policy. Once installed, the GoSolr system will be included as a fitting/fixture to the property and included in the homeowner's insurance policy."
                    ariaLabel="Property Insurance required tooltip"
                    placement="top-end"
                  >
                    <Box
                      position={'absolute'}
                      right={[2, 2, -20, -20]}
                      top={[-2, -2, 7, 7]}
                      cursor={'pointer'}
                    >
                      <IoMdInformationCircleOutline size={25} />
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap={'25px'} direction={'row'} justifyContent={'end'} mt={25}>
              <SubmitButton />
            </Flex>
          </Form>
        )}
      </Formik>
    </OnboardingContainer>
  )
}
export default PropertyLocation

function HomeOwnerObserver({ isHippo }: { isHippo: boolean }) {
  const { values }: FormikState<FormikValues> = useFormikContext()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (values.customerType === 'Tenant' && !isHippo) {
      setModalIsOpen(true)
    }
  }, [values.customerType])

  return (
    <HomeOwnerModal
      isOpen={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false)
      }}
      heading={'Home owner signature required'}
    />
  )
}
