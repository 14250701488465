import React from 'react'
import { Tooltip as ChackaraTooltip, PlacementWithLogical } from '@chakra-ui/react'
type TooltipProps = {
  label: string
  ariaLabel: string
  children: React.ReactNode
  placement?:PlacementWithLogical | undefined
}
const Tooltip: React.FC<TooltipProps> = ({ children, label, ariaLabel,placement}) => {
  

  return (
    <ChackaraTooltip
      label={label}
      aria-label={ariaLabel}
      background={'white'}
      color={'black'}
      rounded={'md'}
      p={4}
      border={'2px solid'}
      borderColor={'brand.input'}
      hasArrow
      closeDelay={500}
      placement={placement?placement:'auto'}
    >
      {children}
    </ChackaraTooltip>
  )
}

export default Tooltip
