import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Text, Heading, Button } from '@chakra-ui/react'
import SubscriptionStatus from './SubscriptionStatus'
import { Maybe, UserProperty } from '../../generated/graphql'
import { DateTime } from 'luxon'
import { capitalizeFirstLetter } from '../../helpers'
import { OnBoardingModal } from '../Modals'

// export type SubscriptionItemProps = {
//   id: string
//   name: string
//   active: boolean
//   type: string
//   streetNumber: number
//   streetName: string
//   suburb: string
//   city: string
//   activeDate: string
//   img_url: string
//   googleMapImage: string
// }

export type SubscriptionItemType = {
  property: UserProperty
  onboardingStepsCompleted?: Maybe<number> | undefined
}

const SubscriptionItem: React.FC<SubscriptionItemType> = ({
  property,
  onboardingStepsCompleted
}) => {
  const {
    suburb,
    subscription,

    description,
    fullAddress,
    installationDate,
    active,

    orderStatus,
    installationComplete,
    installationProcessInitiated,
    recordId
  } = property


  const streetAddr = `${fullAddress} `

  const [onboardingCompleted, setOnboardingCompleted] = useState<boolean>(false)
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false)
  const [completedStepCount, setCompletedStepCount] = useState<number>(5)
  const [failedCreditCheck, setFailedCreditCheck] = useState<boolean>(false)
  const [currentRecordId, setCurrentRecordId] = useState<string>()

  const navigation = useNavigate()

  const handleGoToProperty = (id: string | undefined | null) => {
    navigation(`/user/property/${id}`)
  }
  const [dateOfInstallation, setDateOfInstallation] = useState<string>('')

  useEffect(() => {
    if (onboardingStepsCompleted) {
      setCompletedStepCount(onboardingStepsCompleted)
    }
  }, [onboardingStepsCompleted])

  useEffect(() => {
    if (orderStatus === 'App assist') {
      setOnboardingCompleted(false)
      return
    }

    if (
      orderStatus === 'Agreement Signed' ||
      orderStatus === 'Agreement Signed (incomplete)' ||
      installationComplete ||
      installationProcessInitiated
    ) {
      setOnboardingCompleted(true)
    }

    if (installationComplete || installationProcessInitiated) {
      installationDate
        ? setDateOfInstallation(
            `Completed on ${DateTime.fromISO(installationDate).toFormat('dd LLLL yyyy') as string}`
          )
        : setDateOfInstallation('installation completed')
    } else {
      setDateOfInstallation('Application in progress')
    }
  }, [installationDate])


  useEffect(() => {
    const orderStatus = property.orderStatus
    setFailedCreditCheck(orderStatus === 'Credit Check Failed')
    setCurrentRecordId(property.recordId? property.recordId : "")
  }, [])  

  return (
    <Flex
      flex="1"
      minW={['full', '300px', '500px']}
      direction={['column', 'column', 'row', 'row']}
      p={'10px'}
      rounded={'4px'}
      border={'1px solid #171717'}
    >
      <Flex w={'full'} direction={'column'} gap={'12px'}>
        {/* Heading */}

        <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'}>
          <Heading as={'h3'} fontSize={'20px'}>
            {description ? description : suburb}
          </Heading>
        </Flex>
        {subscription && (
          <Heading as={'h4'} fontSize={'16px'}>
            {capitalizeFirstLetter(
              subscription?.subscriptionOption?.replace('2023_', '') as string
            )}{' '}
            subscription
          </Heading>
        )}
        {!subscription && (
          <Heading as={'h4'} fontSize={'16px'}>
            Please select a new subscription package
          </Heading>
        )}
        {/* address */}
        <Text fontSize={'14px'} color={'brand.text.primary'} maxWidth="300px">
          {streetAddr}
        </Text>

        {/* Active Account */}
        <SubscriptionStatus status={active as boolean} />
        {/* Date */}
        <Flex
          w={'full'}
          alignItems={'center'}
          justifyContent={onboardingCompleted ? 'space-between' : 'flex-end'}
        >
          {onboardingCompleted && (
            <Text fontSize={'12px'} color={'brand.text.secondary'}>
              {dateOfInstallation}
            </Text>
          )}
          {onboardingCompleted  && !failedCreditCheck && (
            <Button variant={'dark'} onClick={(e) => handleGoToProperty(recordId)}>
              View
            </Button>
          )}
          {completedStepCount < 9 && !onboardingCompleted && !failedCreditCheck && (
            <Button variant={'dark'} onClick={() => setShowOnboardingModal(true)}>
              Continue onboarding
            </Button>
          )}
        </Flex>
      </Flex>
      {showOnboardingModal && (
        <OnBoardingModal
          isOpen={showOnboardingModal}
          onClose={() => setShowOnboardingModal(false)}
          recordId={currentRecordId}
          step={onboardingStepsCompleted}
        ></OnBoardingModal>
      )}
    </Flex>
  )
}

export default SubscriptionItem
