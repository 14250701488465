import { DefaultModal } from '../../../Modals'

import MailingSubscribe from '../../../MailingSubscribe'

type PropertyLocationModalProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
  postalCode?: string
}

function PropertyLocationModal({
  isOpen,
  onClose,
  heading,
  postalCode
}: PropertyLocationModalProps) {
  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <MailingSubscribe
        showSubscribeElements={true}
        negativeText="Your property is not in a region we currently support."
        onClose={onClose}
        reasonForSubscribing="Out-of-region"
        comments={postalCode}
      />
    </DefaultModal>
  )
}

export default PropertyLocationModal
