import {
  Flex,
  useRadioGroup,
  Text,
  Wrap,
  WrapItem,
  Center,
  Grid,
  GridItem,
  useMediaQuery
} from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { ROOF_TYPE_OPTIONS } from '../../constants'
import RoofTypeRadio from './RoofTypeRadio'
import { useField } from 'formik'

type Props = { name: string }

const RoofTypeRadioGroup: FC<Props> = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name)

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name,
    defaultValue: field.value,
    onChange: helpers.setValue
  })
  const group = getRootProps()

  return (
    <Flex direction={'column'}>
      <Text fontWeight={600}>Select your roof type:</Text>

      <Grid
        w="full"
        justifyContent={'space-between'}
        gridTemplateColumns={[
          'repeat(3, 1fr)',
          'repeat(5, 1fr)',
          'repeat(7, 1fr)',
          'repeat(7, 1fr)'
        ]}
        gap="15px"
        {...field}
        {...group}
        bg={'brand.input'}
        rounded="md"
        p={4}
      >
        {ROOF_TYPE_OPTIONS.map((type, index) => {
          return (
            <GridItem key={index}>
              <RoofTypeRadio label={type} {...getRadioProps({ value: type })} />
            </GridItem>
          )
        })}
      </Grid>
    </Flex>
  )
}

export default RoofTypeRadioGroup
