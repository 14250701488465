import { Flex, Heading, Box, Tooltip, Link, Icon, FlexProps, HeadingProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'

type TitleType = {
  title: string
  headingColor?: string
  tooltipBgColor?: string
  tooltipTextColor?: string
  color?: string
  tooltip?: React.ReactNode | null
  url?: string
} & FlexProps

const ContainerHeading = forwardRef<HTMLDivElement, TitleType>(
  (
    {
      title,
      headingColor = '#171717',
      tooltipBgColor = '#171717',
      tooltipTextColor = '#fff',
      tooltip = '',
      url = '',
      ...rest
    },
    ref
  ) => {
    return (
      <Flex w={'100%'} alignItems={'start'} {...rest} ref={ref}>
        <Flex w="100%" direction={'column'} gap={'5px'}>
          <Heading as="h2" color={headingColor} fontSize={'20px'} fontWeight={500}>
            {title}
          </Heading>
          <Box w="25px" h="3px" rounded={'full'} bg={headingColor} />
        </Flex>
        {tooltip && (
          <Tooltip
            color={tooltipTextColor}
            bgColor={tooltipBgColor}
            label={tooltip}
            placement="left"
            hasArrow
            borderRadius={'4px'}
          >
            <Flex className="hover-pointer-container">
              <Icon as={FaRegQuestionCircle} color={headingColor} w={5} h={5} />
            </Flex>
          </Tooltip>
        )}
      </Flex>
    )
  }
)
ContainerHeading.displayName = 'container-heading'
export default ContainerHeading
