import { Flex } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'

import MailingSubscribe from '../../../MailingSubscribe'

type InsuranceModalType = {
  isOpen: boolean
  onClose: () => void
  heading: string
}

function InsuranceModal({ isOpen, onClose, heading }: InsuranceModalType) {
  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <MailingSubscribe
          showSubscribeElements={true}
          reasonForSubscribing="Not Insured"
          negativeText="Please note that we require the GoSolr solution to be insured. In case you do not have an insurance, GoSolr will be able to advise on insurance options."
          onClose={onClose}
        />
      </Flex>
    </DefaultModal>
  )
}

export default InsuranceModal
