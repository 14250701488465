import React, { FC } from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  FormControlProps,
  FormErrorMessage,
  Flex
} from '@chakra-ui/react'
import { FieldHookConfig, FieldInputProps, useField } from 'formik'

export type TextFieldProps = {
  label?: string
  maxLength?: number
  readOnly?: boolean
  height?: number
} & FieldHookConfig<string>

const TextInput: FC<TextFieldProps> = ({
  maxLength = 250,
  label = '',
  readOnly = false,
  ...props
}) => {
  const [field, meta] = useField(props)

  return (
    <FormControl isDisabled={props.disabled} isInvalid={meta.touched && !!meta.error}>
      <Flex alignItems={'center'} gap={'10px'} mb={'2px'}>
        <FormLabel
          id={`${props.name}-label`}
          htmlFor={`${props.name}-input`}
          fontSize={'small'}
          m={0}
        >
          {label}
        </FormLabel>
      </Flex>
      <Input
        {...field}
        readOnly={readOnly}
        type={props.type || 'text'}
        id={`${props.name}-input`}
        variant="outline"
        placeholder={props.placeholder}
        spellCheck={false}
        maxLength={maxLength}
        fontSize={'sm'}
        focusBorderColor="brand.primary"
      />
      {meta.error && (
        <FormErrorMessage fontSize={'x-small'} m={0}>
          {meta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default TextInput
